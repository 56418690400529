import React from "react";

import Landing from "../components/sections/Landing";
import Suppliers from "../components/sections/Suppliers";
import About from "../components/sections/About";
import Contact from "../components/sections/Contact";
import HolidayHours from '../assets/images/HolidayHours2024.png';
import Memorial from "../components/sections/Memorial";

import Footer from "../components/shared/Footer";

function Main() {
  return (
    <div id="main">
      <Landing />
      <div style={{ textAlign: "center" }} id="main-content">
        <img
          style={{ display: "inline", padding: "5vw 5vw 0px 5vw" }}
          src={HolidayHours}
          alt="Holiday Hours"
        />
        {/* <Info /> */}
        <Memorial />
        <Suppliers />
        <About />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default Main;
